import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  getListServices() {
    return callApi
      .request({
        method: "GET",
        url: URL.LIST_SERVICES,
      })
      .then((res) => res.data);
  },
  login(data) {
    return callApi.request({
      method: "POST",
      url: URL.LOG_IN,
      data,
    });
  },
  register(data) {
    return callApi.request({
      method: "POST",
      url: URL.REGISTER,
      data,
    });
  },

  getListStyles() {
    return callApi
      .request({
        method: "GET",
        url: URL.STYLES,
      })
      .then((res) => res.data);
  },

  getListFloors() {
    return callApi
      .request({
        method: "GET",
        url: URL.FLOORS,
      })
      .then((res) => res.data);
  },

  getListRoomTypes() {
    return callApi
      .request({
        method: "GET",
        url: URL.ROOM_TYPES,
      })
      .then((res) => res.data);
  },

  getTypeProjectList() {
    return callApi
      .request({
        method: "GET",
        url: URL.PROJECT_TYPES,
      })
      .then((res) => res.data);
  },

  getOrderFeaturesList() {
    return callApi
      .request({
        method: "GET",
        url: URL.ORDER_FEATURES,
      })
      .then((res) => res.data);
  },
};
