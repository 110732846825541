<template>
  <div class="header container">
    <div class="header__logo">
      <img src="/images/logo.png" alt="" />
    </div>

    <div class="header__nav">
      <div class="header__lang">
        <div class="header__lang-icon">
          <enLang />
        </div>
      </div>
      <div class="header__contact">{{ $t("message.Contact") }}</div>
    </div>
  </div>
</template>

<script>
import enLang from "~/assets/images/logo/en-lang.svg";

export default {
  name: "Header",
  components: {
    enLang,
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  position: relative;

  &__nav {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    height: fit-content;
  }

  &__lang {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    height: fit-content;
  }

  &__lang-icon {
    height: 20px;
  }

  &__contact {
    margin-left: 10px;
    padding: 11px 15px;
    border-radius: 8px;
    background: #191919;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: fit-content;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    justify-content: space-between;

    &__nav {
      position: static;
      transform: none;
    }
  }

  @media (max-width: 568px) {
    &__logo {
      img {
        width: 150px;
      }
    }
  }
}
</style>
