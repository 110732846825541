export default {
  LIST_SERVICES: "/api/v1/services",

  LOG_IN: "/api/v1/auth/login",
  REGISTER: "/api/v1/auth/register",

  PROJECT: "/api/v1/projects",

  ROOM: "/api/v1/rooms",

  STYLES: "/api/v1/styles",

  FLOORS: "/api/v1/floor-types",

  ROOM_TYPES: "/api/v1/room-types",

  PROJECT_TYPES: "/api/v1/project-types",

  ORDERS: "/api/v1/orders",

  ORDER_ITEMS: "/api/v1/orders",

  ORDER_FEATURES: "/api/v1/order-features",
};
