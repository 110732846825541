<template>
  <div class="main">
    <header class="main__header">
      <Header />
    </header>
    <main class="main__body">
      <div class="main__content">
        <slot name="content" />
      </div>
    </main>
    <footer class="main__footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
import Header from "../components/layouts/Header.vue";
import baseApi from "~/api/base";
import { setAuthData } from "~/helpers/userAuth";

export default {
  name: "Main",
  components: {
    Header,
  },
  mounted() {
    const data = {
      email: "user@user.com",
      password: "password",
    };
    try {
      baseApi
        .login(data)
        .then((res) => {
          setAuthData(res.data.token, null, {});
        })
        .catch((error) => {
          const user = {
            name: "test",
            email: "user@user.com",
            password: "password",
            password_confirmation: "password",
          };

          baseApi
            .register(user)
            .then((res) => {
              setAuthData(res.data.token, null, {});
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {});
        })
        .finally(() => {});
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    min-width: 100%;
    background: #fff;
    display: flex;
  }

  &__body {
    @include flex-row;
    flex-grow: 1;
  }

  &__content {
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    background: #fff;
    padding-bottom: 90px;
  }
  &__footer {
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    &__content {
      padding-bottom: 120px;
    }
  }
}
</style>
