import callApi from "./index";
import URL from "../constants/urlPaths";

export default {
  createProject(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.PROJECT,
        data,
      })
      .then((res) => res.data);
  },

  updateProject(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.PROJECT + url,
        data,
      })
      .then((res) => res.data);
  },

  getProjectItem(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.PROJECT + url,
      })
      .then((res) => res.data);
  },

  createRoom(url) {
    return callApi
      .request({
        method: "POST",
        url: URL.PROJECT + url,
      })
      .then((res) => res.data);
  },

  updateRoom(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.PROJECT + url,
        data,
      })
      .then((res) => res.data);
  },

  deleteRoom(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.PROJECT + url,
      })
      .then((res) => res.data);
  },

  uploadRoomImage(url, data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.ROOM + url,
        data,
      })
      .then((res) => res.data);
  },

  deleteRoomImage(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.ROOM + url,
      })
      .then((res) => res.data);
  },

  uploadPlanImage(url, data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.PROJECT + url,
        data,
      })
      .then((res) => res.data);
  },

  deletePlanImage(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.PROJECT + url,
      })
      .then((res) => res.data);
  },

  uploadExampleImage(url, data) {
    return callApi
      .request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
        url: URL.PROJECT + url,
        data,
      })
      .then((res) => res.data);
  },

  deleteExampleImage(url) {
    return callApi
      .request({
        method: "DELETE",
        url: URL.PROJECT + url,
      })
      .then((res) => res.data);
  },

  // Order

  createOrder(data) {
    return callApi
      .request({
        method: "POST",
        url: URL.ORDERS,
        data,
      })
      .then((res) => res.data);
  },

  getOrderItem(url) {
    return callApi
      .request({
        method: "GET",
        url: URL.ORDERS + url,
      })
      .then((res) => res.data);
  },

  setOrderItems(url, data) {
    return callApi
      .request({
        method: "PUT",
        url: URL.ORDER_ITEMS + url,
        data,
      })
      .then((res) => res.data);
  },
};
