<template>
  <Main>
    <template v-slot:content>
      <div class="order container">
        <div class="order__container">
          <div class="order__title">Thank you for your order</div>
        </div>
      </div>
    </template>
    <template v-slot:footer></template>
  </Main>
</template>

<script>
import baseApi from "~/api/base";
import projectApi from "~/api/project";
import ROUTE from "~/constants/routes";

import Main from "~/templates/Main.vue";

export default {
  data() {
    return {
      order: null,
    };
  },
  mounted() {
    this.getOrder();
  },
  components: {
    Main,
  },
  methods: {
    getOrder() {
      const url = `/${this.$route.params.id}`;
      try {
        projectApi
          .getOrderItem(url)
          .then((res) => {
            this.order = res;
            if (this.order.items[0].project.service_type.value == "trial") {
              this.is_trial = true;
            }
          })
          .catch((error) => {})
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  justify-content: center;
  min-height: 80vh;
  align-items: center;
  .desc {
    display: block;
  }

  .mob {
    display: none;
  }

  &__container {
    margin-top: 30px;
    min-width: 900px;
    max-width: 1000px;
    padding: 30px;
    border-radius: 15px;
    border: 1px #d7d9dd;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
  }

  &__title {
    font-family: "Mirador";
    font-size: 30px;
  }

  @media (max-width: 1350px) {
    &__container {
      min-width: 750px;
      max-width: 750px;
    }
  }

  @media (max-width: 991px) {
    &__container {
      min-width: 90%;
      max-width: 90%;
      padding: 20px;
    }
  }

  @media (max-width: 568px) {
    &__container {
      min-width: 100%;
      max-width: 100%;
    }

    .desc {
      display: none;
    }

    .mob {
      display: block;
    }

    &__title {
      font-size: 20px;
    }
  }
}
</style>
